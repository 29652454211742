import React, { useMemo, useCallback, useState } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import GoogleIcon from '@system-global/assets/icons/google.svg?react';
import { googleOauthStateHandler } from '@system-global/utils/common';
import styles from './style.module.scss';
import { useRedirect } from '@system-global/hooks/use-redirect';

interface Props {
  type: 'login' | 'register';
  style?: React.CSSProperties;
}

export const GoogleLoginBtn: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);

  const { redirectUrl } = useRedirect();

  const googleLoginUrl = useMemo(() => {
    const googleOAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const clientId = encodeURIComponent('816743693939-r8dmrdn7p5qltvmlq54lotnb6ukd15v6.apps.googleusercontent.com');
    const redirectUri = encodeURIComponent(`${import.meta.env.SYSTEM_GLOBAL_DOMAIN}/oauth/google/handle`);
    const scope = encodeURIComponent(
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid'
    );
    return `${googleOAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&prompt=select_account`;
  }, []);

  const gotoGoogleOAuth = useCallback(() => {
    setLoading(true);
    const newCheckState = Math.random().toString(36).substring(2) + new Date().getTime();
    const newState = newCheckState + (redirectUrl ? `@@@${redirectUrl}` : '');
    googleOauthStateHandler.set(newCheckState);
    setTimeout(() => {
      window.location.href = `${googleLoginUrl}&state=${newState}`;
    }, 1000);
  }, [googleLoginUrl, redirectUrl]);

  return (
    <Button
      id="googleOauthBtn"
      className={styles.button}
      style={props.style}
      block
      onClick={gotoGoogleOAuth}
      loading={loading}
    >
      <GoogleIcon className={styles.icon} />
      <span className={styles.text}>
        <FormattedMessage id={props.type === 'register' ? 'Google登录注册' : 'Google登录'} />
      </span>
    </Button>
  );
};
