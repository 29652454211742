import { StateCreator } from 'zustand';

export enum BindStep {
  BindSite = 'BindSite',
  SelectInstall = 'SelectInstall',
  GTM = 'gtm',
  HTML = 'html',
  Email = 'Email',
  WordPress = 'WordPress',
  Shopify = 'Shopify',
  Wix = 'Wix',
  SquareSpace = 'SquareSpace',
}

export interface IBindSiteInfo {
  name: string;
  siteId: string;
  website: string;
  verifyStatus: string;
}

export interface IBindSlice {
  bind_step: BindStep;
  bind_siteInfo: IBindSiteInfo;
  bind_setStep(step: BindStep): void;
  bind_setSiteInfo(siteInfo: IBindSiteInfo): void;
}

export const createBindSlice: StateCreator<IBindSlice> = set => {
  return {
    /* state */
    bind_step: BindStep.BindSite,
    bind_siteInfo: {
      name: '',
      siteId: '',
      website: '',
      verifyStatus: '',
    },
    /* action */
    bind_setStep(step: BindStep) {
      set(() => ({ bind_step: step }));
    },
    bind_setSiteInfo(bind_siteInfo: IBindSiteInfo) {
      set(() => ({ bind_siteInfo }));
    },
  };
};
