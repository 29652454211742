import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Layout } from 'antd';
import { contentMenus } from '@system-global/config/menus';
import styles from './style.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TongyongJiantou1Shang, TongyongJiantou1Xia, TongyongJiantou2You } from '@sirius/icons';
import { localMenuOpenHandler } from '@system-global/utils/common';
import NewFeedBtn from '../new-feed-sider';

export function ContentRootSider() {
  const location = useLocation();

  const [openKeys, setOpenKeys] = useState<string[]>(localMenuOpenHandler.get() || contentMenus.map(v => v.key));

  const onOpenChange = useCallback((target: string, forceInsert = false) => {
    setOpenKeys(keys => {
      const exist = keys.includes(target);
      const newKeys = forceInsert || !exist ? [...new Set([...keys, target])] : keys.filter(v => v !== target);
      localMenuOpenHandler.set(newKeys);
      return newKeys;
    });
  }, []);

  useEffect(() => {
    const res = location.pathname.split('/');
    onOpenChange(`/${res[2]}`, true);
  }, [onOpenChange, location.pathname]);

  const openKeysStr = useMemo(() => openKeys.join(localMenuOpenHandler.connector), [openKeys]);

  const Menu = useMemo(
    () =>
      contentMenus.map(parent => {
        const _openKeys = openKeysStr ? openKeysStr.split(localMenuOpenHandler.connector) : [];
        const isOpen = _openKeys.includes(parent.key);
        return (
          <div key={parent.key} className={classnames([styles.subMenuWrapper, isOpen ? styles.isOpen : ''])}>
            <div className={styles.subMenu} onClick={() => onOpenChange(parent.key)}>
              <span>{parent.label}</span>
              {isOpen ? <TongyongJiantou1Shang /> : <TongyongJiantou1Xia />}
            </div>
            {Array.isArray(parent.children) && (
              <div
                className={styles.menuItemWrapper}
                style={{ height: isOpen ? parent.children.length * (42 + 2) : 0 }}
              >
                {parent.children.map(child => {
                  const isActive = child.key === location.pathname;
                  return (
                    <Link
                      key={child.key}
                      to={child.key}
                      className={classnames({ [styles.menuItem]: true, [styles.isActive]: isActive })}
                    >
                      <span className={styles.label}>{child.label}</span>
                      {isActive && <TongyongJiantou2You />}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      }),
    [location.pathname, onOpenChange, openKeysStr]
  );

  return (
    <Layout.Sider width={220} className={styles.contentSider}>
      <div className={styles.menuWrapper}>
        <div className={styles.menu}>{Menu}</div>
        <div className={styles.feedBtn}>
          <NewFeedBtn />
        </div>
      </div>
    </Layout.Sider>
  );
}

export default ContentRootSider;
