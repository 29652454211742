import React from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TongyongShangchuan2Mian } from '@sirius/icons';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { useStore } from '@system-global/store';
import InvCodeIcon from '@system-global/assets/icons/inv_code.svg?react';
import styles from './style.module.scss';

export interface InvitationCodeCardProps {
  className?: string;
  style?: React.CSSProperties;
}
export const InvitationCodeCard: React.FC<InvitationCodeCardProps> = props => {
  const { className, style } = props;
  const userInfo = useStore(state => state.user_userInfo);
  const invitationCodeInfo = userInfo?.userInvitationCodeResp;
  const isOfficialUser = userInfo?.userOrderStatusResp?.officialUser;
  const nav = useKeepNavigate();
  const location = useLocation();

  if (!invitationCodeInfo) {
    return null;
  }

  return (
    <div className={classnames(styles.wrapper, className)} style={style}>
      <div className={styles.cardInfo}>
        <div className={styles.icon}>
          <InvCodeIcon />
        </div>
        <div className={styles.detail}>
          <div>
            <FormattedMessage id="验证码优惠" values={{ off: invitationCodeInfo.off }} />
          </div>
          <div>
            <FormattedMessage
              id="验证码期限"
              values={{
                start: dayjs(invitationCodeInfo.effectTime).format('DD/MM/YYYY'),
                end: dayjs(invitationCodeInfo.expireTime).format('DD/MM/YYYY'),
              }}
            />
          </div>
        </div>
      </div>

      {!isOfficialUser && (
        <div className={styles.action}>
          <Button
            block
            className={styles.upgrade}
            icon={<TongyongShangchuan2Mian />}
            onClick={() => {
              if (location.pathname === '/purchase') {
                return;
              }
              nav('/purchase');
            }}
          >
            <FormattedMessage id="升级套餐" />
          </Button>
        </div>
      )}
    </div>
  );
};
