import { safeJsonParse } from '@utils/common';

export enum MessageType {
  SaveLocal = 'SaveLocal',
  RemoveLocal = 'RemoveLocal',
}

// const postMessage = (msg: { type: MessageType; payload: unknown }) => {
//   const iframe = document.getElementById('pmsgFrame') as HTMLIFrameElement;
//   iframe?.contentWindow?.postMessage(msg, import.meta.env.SYSTEM_GLOBAL_DOMAIN);
// };

const saveLocalStorageCrossDomain = (key: string, data: string) => {
  // postMessage({
  //   type: MessageType.SaveLocal,
  //   payload: {
  //     key: key,
  //     data,
  //   },
  // });
  window.localStorage.setItem(key, data);
};

const removeLocalStorageCrossDomain = (key: string) => {
  // postMessage({
  //   type: MessageType.RemoveLocal,
  //   payload: {
  //     key,
  //   },
  // });
  window.localStorage.removeItem(key);
};

/**
 * 登录token
 */
export const localLoginTokenHandler = {
  key: 'leads_navi_token',
  get() {
    return window.localStorage.getItem(localLoginTokenHandler.key) || '';
  },
  set(token: string) {
    saveLocalStorageCrossDomain(localLoginTokenHandler.key, token);
  },
  del() {
    removeLocalStorageCrossDomain(localLoginTokenHandler.key);
  },
};

/**
 * 菜单相关
 */
export const localMenuOpenHandler = {
  key: 'leads_navi_menu_open',
  connector: '@@',
  get() {
    const localStr = window.localStorage.getItem(localMenuOpenHandler.key);
    if (localStr) {
      return safeJsonParse<string[]>(localStr);
    }
  },
  set(openKeysStr: string[]) {
    saveLocalStorageCrossDomain(localMenuOpenHandler.key, JSON.stringify(openKeysStr));
  },
  del() {
    removeLocalStorageCrossDomain(localMenuOpenHandler.key);
  },
};

/**
 * 注册
 */
export interface IRegisterInfo {
  email: string;
  sendTime: number;
}

export const localRegisterInfoHandler = {
  key: 'leads_navi_registering_info',
  get() {
    const localStr = window.localStorage.getItem(localRegisterInfoHandler.key);
    if (localStr) {
      return safeJsonParse<IRegisterInfo>(localStr);
    }
    return undefined;
  },
  set(info: IRegisterInfo) {
    const targetInfo: IRegisterInfo = {
      email: info.email,
      sendTime: info.sendTime,
    };
    saveLocalStorageCrossDomain(localRegisterInfoHandler.key, JSON.stringify(targetInfo));
  },
  del() {
    removeLocalStorageCrossDomain(localRegisterInfoHandler.key);
  },
};

/**
 * Google Oauth state
 */
export const googleOauthStateHandler = {
  key: 'GoogleOauthStateKey',
  get() {
    return window.localStorage.getItem(googleOauthStateHandler.key) || '';
  },
  set(state: string) {
    saveLocalStorageCrossDomain(googleOauthStateHandler.key, state);
  },
  del() {
    removeLocalStorageCrossDomain(googleOauthStateHandler.key);
  },
};
