import { ROUTE_URLS } from '@system-global/config/route-urls';

/**
 * 不需要处理的路径列表
 * 不加载和判定登录状态  用户信息等
 */
export const NothandlePathList = [ROUTE_URLS.quickLogin, ROUTE_URLS.blankPage];

/**
 * 有登陆态，不需要自动跳首页的路径列表
 */
export const NoAutoRedirectPathList = [ROUTE_URLS.googleOAuthHandle, ROUTE_URLS.facebookOAuthHandle];
