const TEXT_MAP: Record<string, string> = {
  基本: 'Basic',
  登录: 'Log In',
  注册: 'Sign Up',
  首页: 'Home',
  注销: 'Logout',
  中文: 'Chinese',
  英文: 'English',
  刷新: 'refresh',
  '对不起，您访问的页面不存在': 'Sorry, the page does not exist.',
  访问统计: 'Total activity',
  访问记录: 'Visit details',
  访客人数: 'Visitor',
  访问时长: 'Duration',
  页面访问次数: 'Page View',
  访问地区: 'Location',
  访问来源: 'Source',
  进入时间: 'Start at',
  退出时间: 'Out at',
  页面地址: 'Location',
  访问次数: 'View',
  地区: 'location',
  '访客#访问了#个页面': 'Visitor {name} visited {count} pages',
  '访客#访问了#个页面单数': 'Visitor {name} visited {count} page',
  _登录介绍:
    "Website visitor treasure will not only identify the visitor, but also analyze the visitor's company type, through AI and related algorithms, find similar companies and recommend to users.",
  设置: 'Personal',
  个人中心设置: 'Setting',
  支付: 'Billing',
  登出: 'Sign out',
  全部公司: 'All companies',
  新公司: 'New companies',
  访问历史: 'History',
  访问概要: 'Overview',
  联系人: 'Contacts',
  相似公司: 'Similar Companies',
  '您尚未登录，请先登录': 'You are not logged in. Please log in first.',
  还没账号吗: 'Don‘t have an account yet?',
  已经有账号了: 'Have an account already?',
  字段不能为空: '{field} can’t be blank',
  请输入正确的邮箱: 'Please provide a valid email address',
  邮箱: 'Email',
  密码: 'Password',
  用户隐私协议: 'Customer Privacy Notice',
  平台隐私协议: 'Platform Privacy Notice',
  登录时同意用户协议: 'By continuing with the log in you agree to our ',
  注册时同意用户协议: 'By continuing with the sign up you agree to our ',
  请阅读并同意隐私协议: 'Please read and agree to our privacy notice',
  邮箱已注册: 'An account with this email already exists.',
  邮箱未注册: 'User not found',
  邮箱待验证: 'Verifying your email to activate your account',
  重发验证邮件: 'Send verification email',
  正在发送邮件: 'Sending email',
  邮箱验证失败: 'Email verification failed, please log in again',
  邮箱验证成功: 'Email verification Successful',
  '密码8-20个字符': 'Between 8 to 20 characters',
  密码至少包含一个字母和一个数字: 'At least 1 number and 1 letter',
  下一步: 'Next',
  你有: 'You have',
  '14天': '14-day',
  免费试用: 'free trial.',
  请同意用户协议: 'Please agree to the Terms & Conditions',
  创造你的账户: 'Create your LeadsNavi account',
  距离获得线索只有一步之遥: 'You are only seconds away from getting a clue!',
  姓: 'First name',
  名: 'Last name',
  公司名称: 'Company name',
  网址: 'Website',
  创建账号: 'Create account',
  后退: 'Back',
  后退到: 'Back to {page}',
  登录成功: 'Log in Successful',
  登录过期: 'Login expired, please log in again',
  网络超时: 'Network timeout, please try again later',
  网络错误: 'Network error, please try again later',
  网站地址: 'Website address',
  请输入公司名称: 'Enter your company name to proceed.',
  请输入网站地址: 'Add your company’s website to unlock targeted leads.',
  网站地址格式错误: 'Please enter a valid website address,e.g https://example.com',
  绑定网站标题: 'Welcome to LeadsNavi!',
  绑定网站描述1: 'Connect your business in seconds—',
  绑定网站描述11: 'no credit card required.',
  绑定网站描述2: 'Easily set up your profile to start generating leads right away.',
  绑定网站公司名称: 'Company Name',
  绑定网站网站地址: 'Website Address',
  绑定网站提示标题: 'Why Continue?',
  绑定网站提示内容1: 'No Credit Card Needed: Experience the platform with zero risk.',
  绑定网站提示内容2: 'Instant Results: Start accessing leads as soon as you connect your website.',
  绑定网站提示内容3: 'Simple & Fast: Just a few clicks to unleash the power of LeadsNavi.',
  绑定网站下一步: 'Continue',
  绑定网站返回: 'Back',
  安装SDK标题: 'Install the LeadsNavi tracker on your website',
  安装SDK描述:
    'The tracker is essential for LeadsNavi to identify the companies visiting your website. It is GDPR compliant and the setup takes only 2 minutes.',
  安装SDK网站域名: 'Your website',
  安装SDK方式: 'Installation methods',
  安装SDK方式描述: 'If you are using a third-party website building platform, we recommend following approaches',
  安装SDK方式1: 'Google Tag Manager',
  安装SDK方式2: 'HTML code',
  安装SDK方式下一步: 'Start',
  安装SDK状态: 'Tracker status',
  安装SDK状态未成功: 'Script not installed',
  安装SDK状态成功: 'Script installed successfully',
  安装步骤1: 'Copy the Website Tracker script',
  安装步骤2: 'Log in to your Google Tag Manager account. Add a new tag and giveit a name, e.g:"Website Tracker"',
  安装步骤3: 'Click "Tag Configuration", select "Custom HTML" and then paste the script',
  安装步骤4: 'Click "Triggering", select "All pages" from the list and then save the Tag',
  安装步骤5: 'Click on the Submit button, and then publish the version to make the new Tag work.',
  安装步骤5描述:
    'If you don’t see the Submit, Publish buttons, ask a colleague who has the access rights to Publish these changes. The newTag won’t work unless the changes are published.',
  安装步骤6: 'Finished all the steps?',
  安装步骤6描述:
    'To verify we’ll open your website to create a visit. lf the tracker is correctly installed, we will redirect you to LeadsNavi in few seconds.',
  安装引导弹窗标题: 'Final Step',
  安装引导弹窗副标题: 'Install the tracker now to unlock this feature and much more',
  安装引导弹窗内容: 'The tracker is indispensable for LeadsNavi to discern the visiting companies on your website.',
  安装引导弹窗描述1: 'See more companies are browsing on your website',
  安装引导弹窗描述2: 'Reveal verified emails of company employees',
  关闭: 'Close',
  复制安装代码: 'Copy script',
  复制ID: 'Copy ID',
  复制成功: 'Successful replication',
  验证安装: 'Verify installation',
  验证中: 'Verifying...',
  SDK验证失败标题: ' Verification failed',
  SDK验证失败描述:
    'We did not detect the Website Tracker on {site}.Please make sure it is installed correctly and verify again.',
  HTML安装步骤2: 'Paste it into the header or footer of your website: {domain}',
  HTML安装步骤3: 'Finished all the steps?',
  HTML安装步骤3描述:
    'To verify we’ll open your website to create a visit. lf the tracker is correctly installed, we will redirect you to LeadsNavi in few seconds.',
  '姓名/职位': 'Contact name / Title',
  帮助中心: 'help center',
  安装SDK帮助提示: 'Visit our { link } for additional help with installing the tracker.',
  安装视频提示: 'How to Setting Up LeadsNavi with Google Tag Manager',
  联系方式: 'Email',
  更新时间: 'Update time',
  忘记密码: 'Forgot password',
  忘记密码吗: 'Forgot password?',
  重置邮件已发送: 'We have e-mailed you the password reset link.',
  发送重置链接: 'Send reset link',
  忘记密码会发邮件: 'Enter your email,we will send you a link to reset your password.',
  重置密码: 'Reset Password',
  重置密码不可用提示: 'You are logged in with a {type} account and cannot reset your password.',
  新密码: 'New Password',
  当前密码: 'Current Password',
  再次: 'Again',
  取消: 'Cancel',
  再次输入密码: 'Please enter your password again.',
  两次密码不一致: 'The passwords do not match.',
  密码修改完成请重新登录: 'Password has been changed, please log in again',
  注册成功: 'Registration successful',
  注册成功欢迎语1:
    'Welcome to LeadsNavi！Your free trial account has been activated.The confirmation been sent to your email ,valid for ',
  注册成功欢迎语2: 'Welcome to LeadsNavi！Your free trial account has been activated.',
  操作: 'Operation',
  '总计#个公司访问了站点': '{totalCompanies} Total companies that have been visited by the system',
  平均每天访问公司数: 'This is the amount of companies per day in the selected time rate for this feed.',
  全部联系人: 'All contacts',
  联系人姓名职位大写: 'CONTACT NAME/TITLE',
  邮箱大写: 'EMAIL',
  公司大写: 'COMPANY',
  联系人解锁时间大写: 'QUERY TIME',
  操作大写: 'OPERATION',
  '总计#公司': '{total} {append}',
  '高级套餐可访问#个公司': 'The higher-tier plan grants you monthly access to a range of {range} companies.',
  服务已到期: 'The maximum limit has been reached.',
  额度已用完: 'The maximum monthly limit of identified companies has been reached.',
  免费体验期已结束: 'Your free experience has expired',
  增加配额: 'Increase Quota',
  '从#服务到#': 'From {serveStartTime} To {serveEndTime}',
  '包含#和#公司，其中#个公司来自#国家#国家':
    'Including {companyA} and {companyB}, there are {companyCount} companies from the {countryA} and {countryB}.',
  '包含#和#公司，其中#个公司来自#国家':
    'Including {companyA} and {companyB}, there are {companyCount} companies from the {countryA}.',
  '包含#和#公司，公司数为#': 'Including {companyA} and {companyB}, there are {companyCount} companies.',
  '还可以免费试用#天': 'You have {days} days left in your trial.',
  体验期已结束: 'Experience  expired.',
  '#天之前': '{days} days ago',
  升级套餐: 'Upgrade',
  今天: 'today',
  昨天: 'yesterday',
  分钟倒计时: '0{minutes}:{seconds}s',
  登出成功: 'Logout successful',
  请验证邮件: 'Verify your email address: {email}',
  邮件已发送请验证1:
    'An email verification link has been sent to your mailbox. Please follow the link to verify your email.',
  邮件已发送请验证2: 'Send verification to your mailbox. Follow the instructions and experience immediately.',
  再次发送: 'Resend',
  验证邮件已失效请重新发送: 'Verification email has expired, please resend.',
  重置密码失败: 'Password reset failed, please try again later.',
  // 访客列表页文案
  '访客列表-名称': 'Name',
  '访客列表-网址': 'Website',
  '访客列表-介绍': 'Introduction',
  '访客列表-来源': 'Sources',
  '访客列表-相似公司': 'Similar',
  '访客列表-停留时长': 'Duration',
  '访客列表-最后访问时间': 'Last visit',
  '访客列表-访问地区': 'Visiting area',
  '访客列表-标题-访问的公司': 'Visit companies',
  '访客列表-筛选-今天': 'Today',
  '访客列表-筛选-本周': 'This week',
  '访客列表-筛选-最近30天': 'Last 30 days',
  '访客列表-筛选-最近90天': 'Last 90 days',
  '访客列表-来源-#家公司': '{count} companies',
  '访客列表-搜索的内容为空': 'No results for your search',
  '访客列表-搜索的内容为空-描述': "Not seeing what you're looking for? Check which search tab you're in",
  '访客列表-按照最后访问时间排序': 'Sort by last visit',
  '访客列表-按照重要性排序': 'Sort by importance',
  '访客列表-最后访问时间排序': 'Last visit',
  '访客列表-重要性排序': 'importance',
  // 访客统计卡片文案
  '访客统计-洞察': 'Insights',
  '访客统计-公司总数量': 'Total Companies',
  '访客统计-总计#个公司访问了站点': '{totalCompanies} Total companies that have been visited by the system',
  '访客统计-平均每日访问公司数描述': 'This is the amount of companies per day in the selected timerame for this feed.',
  '访客统计-平均每日访问公司数': 'Average companies per day',
  '访客统计-公司排名': 'TOP Companies',
  '访客统计-行业排名': 'TOP INDUSTRIES',
  '访客统计-国家排名': 'TOP COUNTRIES',
  '访客统计-展开更多': 'Show more',
  '访客统计-收起': 'Show less',
  今日: 'Today',
  本周: 'This week',
  近30天: 'Last 30 Days',
  近90天: 'Last 90 Days',
  '共#个公司': '{total} companies',
  '共#个联系人': '{total} {append}',
  '共#数据': 'Total {total}',
  // 相关性文案
  '相关性-相关': 'Relevant',
  '相关性-很相关': 'More relevant',
  '相关性-最相关': 'Most relevant',
  // 数据导出弹层相关文案
  '数据导出-介绍': "Please specify the data you want to export. We'l send you a download link once it's ready.",
  '数据导出-标题': 'Export',
  '数据导出-导出访客按钮提示': 'Export Companies',
  '数据导出-导出联系人按钮提示': 'Export Contacts',
  '数据导出-标题-格式': 'FORMAT',
  '数据导出-标题-邮箱': 'EMAIL TO',
  '数据导出-确定': 'Generate Export',
  '数据导出-取消': 'Cancel',
  '数据导出-成功提示#': 'We will send a download link to {email} as soon as it is ready.',
  复制: 'Copy',
  去首页: 'Go to Homepage',
  // 购买套餐tooltip
  联系客户经理: 'Contact account manager',
  '给我们的客户经理发邮件，我们将迅速响应您的需求':
    'Email our account manager and we will respond quickly to your needs.',
  个人信息: 'Personal Details',
  订阅: 'Subscriptions',
  上述详情仅对您可见: 'The following details are visible only to you.',
  个人头像: 'Profile Image',
  解锁失败: '解锁失败',
  国家: 'Country',
  公司官网: 'Website',
  公司地址: 'Location',
  所属行业: 'Industry',
  公司电话: 'Phone',
  成立年份: 'Established',
  公司收入: 'Revenue',
  公司员工: 'Employees',
  通用错误页面提示:
    'It looks like something went wrong. Our website encountered an error at this location. Please try again later. We apologize for any inconvenience.',
  支付数据不足标题: 'We need to collect data for {days} days to provide an accurate price.',
  支付数据不足描述1:
    'The pricing model for LeadsNavi is based on your average traffic.To ensure we calculate the most fair and accurate monthly price for you,we need to collect data for at least 7days.',
  支付数据不足描述2:
    'Altematively,speak with a product expert about a personalized quote and learn how Leadfeeder can create value for your business.Please select a convenient time slot from the following.',
  支付联系产品客服: 'Chat with a product expert',
  支付留资提交成功: 'Reservation successful',
  支付留资提交成功标题: 'Reservation successful',
  支付留资提交成功描述: 'Thank you for your interest in LeadsNavi.',
  支付留资提交成功确认: 'Go Back',
  订单标题: 'Plan & Pricing Details',
  订单描述:
    'Based on your monthly traffic, Based on your traffic, we estimate that you will get access to: {amountPerMonth} Companies per month, we recommend the {recommendTypeName} plan.',
  订单选项: 'Product Solution',
  订单价格信息: 'Subscription Overview',
  订单年付: 'Annual Plan',
  订单月付: 'Monthly Plan',
  订单期限: 'You are purchasing an {type} plan ( {start} - {end} ).',
  订单期限年度: 'This subscription is valid from {start} to {end}.',
  订单期限月度: 'This subscription is valid from {start} to {end}, and will renew monthly unless canceled.',
  订单项目: 'Web Visitors Identification',
  订单促销码: 'Promo Code',
  订单邀请码: 'Invitation',
  订单价格: 'Total cost',
  折扣XX: 'Save {discount}%',
  购买: 'Purchase',
  订单服务协议: 'Terms of Service',
  订单协议: "By subscribing to the service, You have accepted LeadsNavi's { link }.",
  IP识别额度: 'Companies Identified / mo',
  客户识别额度: 'Contact / mo',
  公司数量: 'Company User',
  浏览数据存储量: 'Visit Data Storage',
  等待支付结果: 'Waiting for payment result',
  订单已过期: 'Payment results expired',
  支付成功: 'Your payment sucessfully processed',
  支付失败: 'Sorry, your payment was not successful. Please check your payment information and try again',
  返回到支付页面: 'Return payment',
  返回到系统: 'Back to LeadsNavi',
  自定义订单说明:
    ' If you need greater capacity or customized use cases, feel free to discuss personalized pricing with our product experts.',
  联系销售人员按钮: 'Chat with a product expert',
  无限制: 'Unlimited',
  公司设置: 'Company',
  SDK信息菜单: 'Tracker',
  SDK信息标题: 'Tracker',
  网站状态: 'Website Statuses',
  接入代码: 'Tracking Code',
  手动接入: 'Manual',
  接入步骤1:
    'Go to your workspace in Google Tag Manager and click new tag. Click Tag configuration and choose custom HTML from the list. Copy the code below and paste it into the HTML field.',
  接入步骤1提示:
    'Select a Trigger that has trigger type page view and set it to trigger on all page views. And save the tag.',
  脚本ID: 'Your script ID',
  接入步骤2: 'Simply paste the tracking code at the bottom of the head tag on all pages.',
  接入步骤2提示: 'If not managing the website, pass it to the right person.',
  最近7天事件数: 'Event last 7 days',
  最后一条事件: 'Last event',
  支付价格: 'Price',
  支付类型: 'Type',
  服务有效期: 'Validity',
  查看订单: 'View order',
  去体验: 'To experience',
  体验过期标题: 'Your trial period has expired',
  体验过期描述1: 'We hope you enjoyed your trial. ',
  体验过期描述2: 'We’d love to show you how to get the most out of LeadsNavi and give access to all product features.',
  继续体验: 'Continue',
  过去30天的公司: 'Companies In Last 30 Days',
  旗舰价格: 'Estimated Price',
  订购状态: 'Status',
  访问过的公司: 'NO. of Visit Companies',
  开始时间: 'Start Date',
  结束时间: 'End Date',
  // 邮件通知
  邮件通知: 'Email Notifications',
  邮件通知描述:
    'Companies that visit your site will send you an email, and you can customize the email notification rules.',
  新建邮件通知: 'Create a new feed',
  通知邮件上限提示: 'The number of feeds reached the maximum of 5',
  创建邮件的标题: 'Create a new feed',
  编辑邮件的标题: 'Edit the feed',
  feed名称: 'FEED NAME',
  feed过滤: 'FILTER',
  feed过滤说明: 'Filters allow you to set criteria to receive only relevant companies for a certain Custom Feed.',
  feed名称提示文案: 'Please enter a feed name',
  feed创建成功: 'Feed creation successful',
  feed更新成功: 'Feed update successful',
  保存Feed: 'Save feed',
  feed发送频率: 'NOTIFICATION INTERVAL',
  feed日: 'Daily',
  feed周: 'Weekly',
  feed邮件接收: 'Please enter the email address you want to send',
  feed接受者: 'MAIL TO',
  创建一个过滤: 'Create a new filter',
  过滤条件的上限: 'The number of filters reached the maximum of 5',
  邮件通知编辑: 'Edit',
  邮件通知删除: 'Delete',
  feed删除成功: 'Delete successful',
  通知频繁修改成功: 'Modification successful',
  创建feed: 'Create a Feed',
  Google登录注册: 'Sign up with Google',
  Google登录: 'Log in with Google',
  Facebook登录注册: 'Sign up with Facebook',
  Facebook登录: 'Log in with Facebook',
  AuthStateError: 'The current URL has been tampered with. Please try again.',
  AuthOperationError: 'Login authorization failed. Please try again.',
  返回登录页: 'Back to login',
  邮箱账号已被注册: 'This email is already in use.',
  使用密码登录提示: 'If you already have an account, please Log in.',
  使用google登录提示: 'Your email has been registered through a Google account. Please log in directly.',
  使用facebook登录提示: 'Your email has been registered through a Facebook account. Please log in directly.',
  使用密码去登录: 'Use password to login',
  样例数据: 'Sample Data',
  样例数据说明1: "Click on the company name {companyName} to view the visitor's details and contact information",
  样例数据说明2: `This LeadsNavi is sample data, not actual visitorsNow {link} to view more visitor logs.`,
  欢迎来到ln: 'Welcome to LeadsNavi',
  绑定提示: '{link} on your website and see the pages companies are browsing on your website.',
  现在安装: 'Install Now',
  全部站点: 'All Websites',
  邀请码: 'Invitation Code',
  邀请码不能为空: 'Please enter the invitation code',
  我有邀请码: 'I have an invitation code',
  邀请码placeholder: 'Enter the code and get a discount',
  邀请码错误: 'The invitation code is incorrect',
  功能集成: 'Integration',
  hubspot: 'HubSpot',
  hubspot字段设置: 'Customization',
  hubspot用户映射: 'User Matching',
  hubspot错误日志: 'Errors',
  添加到CRM: 'Add to CRM',
  查看CRM链接: 'View CRM Detail',
  确定: 'Confirm',
  连接详情: 'Connection Details',
  连接详情描述: 'Manage the connection of your integration.',
  解绑: 'Disconnect',
  连接状态: 'Connection status',
  已连接: 'Connected',
  未连接: 'Not Connected',
  连接日期: 'Date Connected',
  字段设置: 'Settings',
  字段设置公司: 'Companies',
  字段设置公司描述: 'Customize the fields for companies.',
  字段设置联系人: 'Contacts',
  字段设置联系人描述: 'Customize the fields for contacts.',
  LeadsNavi字段: 'LeadsNavi Field',
  Hubspot字段: 'HubSpot Field',
  Hubspot连接说明标题: 'Automate your sales work in HubSpot.',
  Hubspot连接说明描述1:
    '- Find new potential customers and know what they do on your website. Do this by smoothly connecting company and contact details with HubSpot.',
  Hubspot连接说明描述2:
    "- Sync useful data about their intentions. This helps your teams to personalize how they reach out at the proper moment. You'll also get notices when companies in your CRM visit your website.",
  Hubspot连接按钮: 'Connect with HubSpot',
  Hubspot连接协议标题: 'LeadsNavi Integration with HubSpot Terms & Conditions',
  Hubspot连接协议内容:
    'Seamlessly integrate LeadsNavi with HubSpot to supercharge your business processes and drive deal closures with enhanced efficiency:',
  Hubspot连接协议子标题1: 'Data Synchronization and Utilization',
  Hubspot连接协议内容1:
    'Company and Contact Creation: LeadsNavi empowers you to populate your HubSpot account with the freshest data, ensuring that companies and contacts are accurately and promptly established, keeping your records up-to-date for optimal business operations.',
  Hubspot连接协议子标题2: 'Data Storage and Processing',
  Hubspot连接协议内容2:
    "We handle and process your HubSpot contact information only to match website visitors with HubSpot contacts and leads with HubSpot companies precisely. We won't repurpose or use it otherwise. If you disagree with this data usage, please don't integrate with HubSpot.",
  Hubspot连接协议子标题3: 'Getting Started Guide',
  Hubspot连接协议内容3:
    'Please ensure that you possess the administrative privileges for the HubSpot account you intend to connect with LeadsNavi.',
  Hubspot连接协议check: 'By continuing with the Intergration you agree to our Terms',
  Hubspot连接协议不可点提示: ' Please agree to the terms before continuing.',
  Hubspot连接协议同意并继续: 'Agree and Continue',
  Hubspot连接失败: 'Connection failed. Please reconnect.',
  Hubspot重复链接提示: 'This account has already been connected to HubSpot.',
  Hubspot默认用户设置提示: 'Select from dropdown menu which HubSpot user does LeadsNavi user match to:',
  Hubspot用户: 'HubSpot User:',
  LeadsNvi用户: 'LeadsNavi User:',
  Hubspot错误日志类型: 'Error Type',
  Hubspot错误日志描述: 'Description',
  Hubspot错误日志时间: 'Trigger Time',
  Hubspot解除链接提示标题: 'Do you want to disconnect?',
  Hubspot解除链接提示:
    'After disconnecting, the browsing data and other information of the companies in LeadsNavi will not be pushed to Hubspot',
  Hubspot公司解除链接提示标题: 'Do you want to disconnect?',
  Hubspot公司解除链接提示:
    'After disconnecting, the browsing data and other information of the company in LeadsNavi will not be pushed to Hubspot',
  Hubspot联系人解除链接提示标题: 'Do you want to disconnect?',
  Hubspot联系人解除链接提示:
    'After disconnecting, the browsing data and other information of the contact in LeadsNavi will not be pushed to Hubspot',
  Hubspot公司连接标题: 'CRM Intergration',
  Hubspot账号连接成功: 'Great! you have successfully completed the HubSpot connection.',
  Hubspot公司连接成功: 'Great! you have successfully completed the HubSpot company connection.',
  Hubspot联系人连接成功: 'Great! you have successfully completed the HubSpot contact connection.',
  Hubspot公司解绑成功: 'Company disconnect successful',
  Hubspot联系人解绑成功: 'Contact disconnect successful',
  Hubspot账号解绑成功: 'Disconnect successful',
  Hubspot公司连接账号连接: 'Hubspot Connect',
  Hubspot公司连接账号连接描述:
    'Connect LeadsNavi company to Hubspot to keep your sales team on track of their behaver.',
  连接: 'Connect',
  连接中: 'Connecting',
  连接loading关闭提示: 'If you turn this off, you may need to manually refresh to get the latest connection status',
  Hubspot公司关联标题: '{ companyName } Connection with HubSpot.',
  Hubspot联系人关联标题: '{ contactName } Connection with HubSpot.',
  Hubspot公司关联说明:
    'You can either connect to an existing HubSpot company or create a new company. For new companies, a HubSpot user can be selected as an owner.',
  Hubspot公司关联创建新公司: 'Create a new company in HubSpot',
  Hubspot联系人关联创建新联系人: 'Create a new contact in hubSpot',
  所有者: 'OWNER',
  名称: 'NAME',
  名称Placeholder: 'Please enter the company name',
  联系人名称Placeholder: 'Please enter the contact name',
  创建: 'Create',
  修改: 'Edit',
  已连接公司: 'Conneted to Company: ',
  连接公司所有者: 'Owner: ',
  连接联系人姓名: 'Name: ',
  Hubspot联系人关联说明: '',
  公司已连接Hubspot: 'Company connected in Hubspot',
  联系人已连接Hubspot: 'Contact connected in Hubspot',
  已连接到Hubspot: 'Connected to Hubspot',
  H5发送邮件标题: 'Email verification successful',
  H5发送邮件描述:
    'An email with tracking script and detailed installation instructions has been sent to { email }. Please check your email and start your free trial on a PC.',
  H5发送邮件表单标题: 'Send to website developer:',
  H5发送邮件表单描述: 'Send it to the website developers so they can handle it quickly for you.',
  H5发送邮件邮箱placeholder: 'example@mail.com',
  H5发送邮件预览: 'Email preview',
  H5发送邮件提交: 'Send script',
  H5发送邮件跳过: 'Skip',
  H5发送邮件成功提示: 'Email has been sent successfully to { email }',
  H5去PC引导: 'Visit us on a PC to experience all our features!',
  H5APP地址引导: 'Our official link: {link}',
  Token不合法或者已过期: 'Token is invalid or has expired',
  SDK其它安装方式: 'Other methods',
  SDK其它安装方式描述: 'If you want to install LeadsNavi in a different way',
  SDK其它安装方式选项: 'Send script via email',
  SDK其它安装方式标题: 'Send tracking script and installation instructions to website developer',
  SDK其它安装方式邮箱: 'Email To',
  SDK其它安装方式邮箱预览: 'Email preview',
  SDK其它安装方式邮箱Placeholder: 'example@mail.com',
  SDK其它安装方式发送: 'Send script',
  SDK其它安装方式成功提示: 'Email has been sent successfully to { email }',
  SDK其它安装方式提示:
    'Remind your colleague to access it on a PC. Upon completion, an automatic email notification will be sent to you.',
  H5绑定网站标题: 'Welcome to LeadsNavi!',
  H5绑定网站标题2: 'You are seconds away from your leads!',
  H5绑定网站描述: 'Finish your profile to get started. Please enter your company details.',
  H5绑定网站提交: 'Continue',
  H5绑定网站公司名: 'Company Name',
  H5绑定网站网址: 'Website address',
  H5绑定网站网址Placeholder: 'Website address',
  H5绑定网站网址格式错误: 'Please enter a valid website address',
  验证码优惠: 'Benefit: {off}% OFF',
  验证码期限: 'Validity: {start} - {end}',
  价格: 'Price',
  自动续费: 'auto renew',
  下个付款日: 'Next payment date: {date}',
  取消订阅: 'Unsubscribe',
  操作提示: "We're sad to see you go!",
  取消订阅提示:
    'If you confirm to end the subscription, you can still access it until {date}. We appreciate your time with us. Hope to see you soon!',
  取消订阅成功: 'Unsubscribe successful',
  oauth重发邮件标题: 'Your email has been registered',
  oauth重发邮件说明: 'Please resend the verification email to activate your account',
  wix安装步骤2: 'Within your Wix dashboard, open {menu}, click on "Add Custom Code"',
  wix安装步骤3: 'Paste the tracking code, select "All pages", select place code in "HEAD", click "Apply"',
  wix安装步骤4: 'Finished all the steps',
  wix安装步骤4说明:
    "To verify we'll open your website to create a visit. If the tracker is correctly installed, we will redirect you to Leadsnavi in few seconds.",

  wordpress安装步骤2: 'Log in to your WordPress admin account. Open {menu}',
  wordpress安装步骤3: 'Search for "Insert Headers and Footers" in the search box to install and activate it.',
  // wordpress安装步骤3说明: 'Search for "{keyWord}" in the search box to install and activate it.',
  wordpress安装步骤4: 'Within your wordpress dashboard open {menu}',
  wordpress安装步骤4说明: 'Settings >WP headers and footers',
  wordpress安装步骤5:
    'Entered the plugin interface, paste the  Website Tracker script  into the "Header" section and click "Save changes" to apply the changes.',
  wordpress安装步骤5说明: 'Make sure to clear the cache in WordPress to make it work instantly.',
  wordpress安装步骤6: 'Finished all the steps',
  wordpress安装步骤6说明:
    "To verify we'll open your website to create a visit. If the tracker is correctly installed, we will redirect you to Leadsnavi in few seconds.",
  shopify安装步骤2: 'Within your shopify dashboard, open {menu}, click "..." and then "Edit code"',
  shopify安装步骤3: 'Click on {menu}',
  shopify安装步骤4: 'Select place code in "HEAD", Paste the tracking script, click "Save"',
  shopify安装步骤5: 'Finished all the steps',
  shopify安装步骤5说明:
    "To verify we'll open your website to create a visit. If the tracker is correctly installed, we will redirect you to Leadsnavi  in few seconds.",
  squareSpace安装步骤2: 'Log in to Squarespace, then navigate to {menu}',
  squareSpace安装步骤2说明: 'Must be Business or Commercial Plan',
  squareSpace安装步骤3: 'Paste the tracking code in the Header section, then click "Save"',
  squareSpace安装步骤4: 'Finished all the steps',
  squareSpace安装步骤4说明:
    "To verify we'll open your website to create a visit. If the tracker is correctly installed, we will redirect you to Leadsnavi  in few seconds.",
  安装SDK方式Wix: 'Wix',
  安装SDK方式Wordpress: 'Wordpress',
  安装SDK方式Shopify: 'Shopify',
  安装SDK方式SquareSpace: 'SquareSpace',
  验证SDK提示: 'Please verify the installation of the tracker on your website',
  验证SDK提示1: 'Please make sure the tracker has been successfully installed.',
  验证SDK提示2: "We need to open your website to create a visit for verification, please don't close the window.",
  验证SDK提示3: "It may take a few minutes to check your tracker's binding status, please sit tight.",
  验证SDKloading: "We're working on it...It may take a few minutes due to the network speed.",
  验证SDK成功: 'All Set! Your website is now connected',
  验证SDK成功提示: "You'll be redirected automatically in {second} seconds...",
  验证SDK失败:
    'We did not detect the Website Tracker on {site}. Please make sure it is installed correctly and verify again.',
};

export default TEXT_MAP;
