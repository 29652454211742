import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import MaskLoading from '@ui/components/mask-loading';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import ErrorIcon from '@system-global/assets/icons/res_error.svg?react';
import styles from './styles.module.scss';

export const StateError = () => {
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  const shouldBackToLogin = useMemo(() => {
    if (error === 'access_denied') {
      return true;
    }
    return false;
  }, [error]);

  useEffect(() => {
    if (shouldBackToLogin) {
      nav(ROUTE_URLS.login, { replace: true });
    }
  }, [shouldBackToLogin, nav]);

  if (shouldBackToLogin) {
    return <MaskLoading />;
  }

  return (
    <div className={styles.error}>
      <ErrorIcon />
      <h1>
        <FormattedMessage id={error ? 'AuthOperationError' : 'AuthStateError'} />
      </h1>
      <Button type="primary" size="large" onClick={() => nav(ROUTE_URLS.login, { replace: true })}>
        <FormattedMessage id="返回登录页" />
      </Button>
    </div>
  );
};
