import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'antd';
import classnames from 'classnames';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@system-global/store';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import LoginStyle from '@system-global/layout/sub-layouts/login-root/styles.module.scss';
import IconInfo from '@system-global/assets/icons/icon_Info.svg?react';
import styles from './styles.module.scss';

interface Props {
  email: string;
}
export const ResendTip: React.FC<Props> = props => {
  const nav = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const doResendEmail = useStore(state => state.login_doResendEmail);

  const resendEmail = useCallback(async () => {
    try {
      setLoading(true);
      await doResendEmail('signup', props.email);
      setTimeout(() => nav(ROUTE_URLS.verifyEmail), 500);
    } finally {
      setLoading(false);
    }
  }, [nav, props.email, doResendEmail]);

  return (
    <div className={classnames(styles.loginTip, LoginStyle.loginRoot)}>
      <LoginContainer>
        <div className={styles.tipContent}>
          <div className={styles.title}>
            <IconInfo className={styles.icon} />
            <FormattedMessage id="oauth重发邮件标题" />
          </div>
          <div className={styles.subTitle}>
            <FormattedMessage id="oauth重发邮件说明" />
          </div>
          <Form layout="vertical">
            <Form.Item label={intl.formatMessage({ id: '邮箱' }).toUpperCase()}>
              <div className={styles.emailInput}>{props.email || ''}</div>
            </Form.Item>
          </Form>
          <Button type="primary" size="large" className={styles.confirmBtn} loading={loading} onClick={resendEmail}>
            <FormattedMessage id="再次发送" />
          </Button>
        </div>
      </LoginContainer>
    </div>
  );
};
