import React, { useMemo, useCallback, useState } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import FacebookIcon from '@system-global/assets/icons/facebook2.svg?react';
import { googleOauthStateHandler as oauthStateHandler } from '@system-global/utils/common';
import styles from './style.module.scss';
import { useRedirect } from '@system-global/hooks/use-redirect';

interface Props {
  type: 'login' | 'register';
  style?: React.CSSProperties;
}

export const FacebookLoginBtn: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);

  const { redirectUrl } = useRedirect();

  const facebookLoginUrl = useMemo(() => {
    const oAuthUrl = 'https://www.facebook.com/v18.0/dialog/oauth';
    const clientId = encodeURIComponent('854707603277393');
    const redirectUri = encodeURIComponent(`${import.meta.env.SYSTEM_GLOBAL_DOMAIN}/oauth/facebook/handle`);
    const scope = encodeURIComponent('public_profile,email');
    return `${oAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&prompt=select_account&scope=${scope}`;
  }, []);

  const gotoFacebookOAuth = useCallback(() => {
    setLoading(true);
    const newCheckState = Math.random().toString(36).substring(2) + new Date().getTime();
    const newState = newCheckState + (redirectUrl ? `@@@${redirectUrl}` : '');
    oauthStateHandler.set(newCheckState);
    setTimeout(() => {
      window.location.href = `${facebookLoginUrl}&state=${newState}`;
    }, 800);
  }, [facebookLoginUrl, redirectUrl]);

  return (
    <Button
      id="facebookOauthBtn"
      className={styles.button}
      style={props.style}
      block
      onClick={gotoFacebookOAuth}
      loading={loading}
    >
      <FacebookIcon className={styles.icon} />
      <span className={styles.text}>
        <FormattedMessage id={props.type === 'register' ? 'Facebook登录注册' : 'Facebook登录'} />
      </span>
    </Button>
  );
};
