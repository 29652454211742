import { useMemo } from 'react';
import MaskLoading from '@ui/components/mask-loading';
import RegisterForm from './regForm';
import { LoginTip } from '../../common/login-tip';
import { StateError } from '../../common/state-error';
import { useLoginState, LoginState } from '../../hooks/useLoginState';
import styles from './styles.module.scss';

const GoogleAuthHandle = () => {
  const { state, email, accessCode } = useLoginState('GOOGLE');

  const content = useMemo(() => {
    if (state === LoginState.Loading) {
      return <MaskLoading />;
    }

    if (state === LoginState.Register) {
      return <RegisterForm defaultEmail={email} accessCode={accessCode} />;
    }

    if (state === LoginState.StateError) {
      return <StateError />;
    }

    return <LoginTip email={email} state={state} />;
  }, [state, email, accessCode]);

  return <div className={styles.wrapper}>{content}</div>;
};

export default GoogleAuthHandle;
