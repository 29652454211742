/** 商品套餐相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 商品套餐相关 取消订阅 */
export async function cancelSubscriptionPost(
  payload: model.IApiCancelSubscriptionReq,
  customParams?: IAxiosCustomParams
) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/product/cancelSubscription`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 商品套餐相关 轮询检查订单状态 */
export async function checkOrderStatusGet(
  payload: {
    payOrderId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultPayOrderStatusResp>({
    url: `/api/biz/product/checkOrderStatus`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 商品套餐相关 下单 */
export async function createPayOrderPost(payload: model.IApiCreatePayOrderReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultCreateOrderResp>({
    url: `/api/biz/product/createPayOrder`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 商品套餐相关 当前套餐信息 */
export async function currentBusinessPackageGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultBusinessPackageResp>({
    url: `/api/biz/product/currentBusinessPackage`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 商品套餐相关 商品套餐列表 */
export async function productListGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultProductListResp>({
    url: `/api/biz/product/productList`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 商品套餐相关 流量推算 */
export async function trafficCalculationGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultTrafficCalculateResp>({
    url: `/api/biz/product/trafficCalculation`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
